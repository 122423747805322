import React from 'react'
import styles from "./teaser.module.scss"


class Teaser extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hasClicked: false
        };
    }

    showPreview = () => {
        const video = this.refs.vidRef;
        const{previewGif} = this.props;
        if(window && video) {
            video.setAttribute('poster',`/images/previews/gif/${previewGif}`);
        }
    }

    onClick = () => {
        const video = this.refs.vidRef;
        if (video.paused === true) {
            document.querySelectorAll('video').forEach(video => {
                video.pause();
            });
            video.play();
        } else {
            video.pause();
        }
    }

    render() {
        const{preview, videoLocation} = this.props;
        return (
          <div className={styles.videoMask}>
              <video ref="vidRef" onClick={this.onClick} className={styles.preview} poster={`/images/previews/${preview}`} controls onMouseEnter={this.showPreview}>
                    <source src={`https://f002.backblazeb2.com/file/teasers/${videoLocation}`} type="video/mp4"/>
                </video>
          </div>
        )
    }
}

export default Teaser
