import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'
import Teaser from '../../components/Teaser'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to Crop and Resize a Video to Get The Best Results"
          description="A quick and easy guide to cropping videos to make them stand out on social media and look more professional."
        />

        <article>
          <h1>How to Crop and Resize a Video to Get The Best Results</h1>

          <p>
            Whether you are a social media videos content creator, influencer, or just someone that loves making movies,
            there are many reasons why editing your videos can be beneficial. There are also numerous applications that
            can achieve what you want with your videos. However, it isn’t as simple as cropping and resizing video,
            there are elements you should consider before you start.
          </p>

          <p>
            <strong>Why do you need to edit the video?</strong>
          </p>

          <p>
            Many professional editors will use applications to change the video aspect ratio. When you have filmed the
            scene or video, there may be elements that are not wanted in the shot. These can be easily removed and the
            aspect changed to focus on a particular part.
          </p>

          <p>
            For example, there may be an area of the shot that the editor feels he wants the viewer to see. By cropping
            video and resizing the shot, they can achieve this without having to reshoot the entire video.
          </p>

          <p>
            Another reason for cropping would be if a video is recorded on a mobile device. Sometimes, there can be
            black bars on the finished video that should be removed. If the video is of sufficient quality, the editor
            can crop the bars and zoom in on the footage to adjust the aspect ratio.
          </p>

          <p>
            <strong>What are the benefits of cropping your video?</strong>
          </p>

          <p>
            Video is one of the best ways to tell a story, however, there are ways to make what is being shown more
            compelling or dramatic depending on the editing.
          </p>

          <ul>
            <li>
              Social Media - Social media is the genre most people will be using for their video. It doesn’t matter
              whether they are for family or as a way to influence your audience, most videos can benefit from editing.
              Social media, in particular, reacts well to video, with more people clicking on a picture or video than on
              a text post.
            </li>
            <li>
              Framing - You may hear framing talked about on editing applications. This process involved cropping and
              resizing video so that the part you want to see is in the middle of the frame. Your viewers will then be
              naturally drawn to this part of the video instead of relying on them to see it themselves.
            </li>
            <li>
              Displaying Multiple Clips - If you intend to display more than one clip on-screen simultaneously, then
              cropping and resizing will help you to show what is happening in each clip in more detail.
            </li>
            <li>
              Removing mistakes - When reviewing a video, you may see something that has got into the shot that you
              don’t want. Perhaps the mic is showing, or possibly people have wandered into the frame. Instead of
              reshooting that part, you can crop it out.
            </li>
          </ul>

          <p>
            <strong>What should you look for in video editing software?</strong>
          </p>

          <p>
            A search of the internet will conjure up a multitude of applications for editing videos. On the surface,
            they may all look similar, however, there will be differences that will appeal to different people depending
            on the results they want and their level of experience.
          </p>

          <ul>
            <li>
              Compatibility - Although most video editing software will be compatible with a variety of systems, it is
              worth checking that the one you choose will work with the device you are using. For example, some software
              will work on a Windows device, but won’t have an app for Android. This may be a factor if you want to work
              across many devices.
            </li>
            <li>
              Price - Depending on what you are willing to pay, there will be a wide range of prices for software. While
              there are free applications available, these may have limitations that will make them unsuitable for you.
              You can find software that will require a one-off cost or a subscription plan. Choose the one that suits
              your needs best to save you money.
            </li>
            <li>
              Experience - As a beginner, you want an application that will guide you through the process and have a
              simple interface that is easy to use.
            </li>
            <li>
              Requirements - Video editing software will demand a lot from your machine. You need to choose an
              application that your device can run and not cause any lagging or crashing issues.
            </li>
          </ul>

          <p>
            <strong>How to crop and resize video</strong>
          </p>

          <p>
            Once you have chosen the software you want, the process for cropping your videos is usually straightforward.
          </p>
          <ul>
            <li>
              Upload - Firstly, you need to upload your video file to the video editing software. There will usually be
              an interface that will let you either drag and drop, or select the file from your device.
            </li>
            <li>
              Cropping - Once the video has uploaded, you will see a crop symbol that looks like a pair of scissors.
              When selected, markings will appear at the edge of the video where you can drag the sides in. This will
              allow you to cut out any parts you need. You can then accept the changes.
            </li>
            <li>
              Resizing - At this point, you can resize the video to conform to the requirements of the platform you are
              using. For example, Instagram stories require the video to be in portrait format, so you will need to
              adjust your finished video to match.
            </li>
            <li>
              Other Adjustments - As you become more experienced with the software, you may want to make more
              adjustments to the video. This may include using filters, adding text, or mixing music into the video.
            </li>
            <li>
              Saving - When you have completed the editing, you can save the file to your device or to the cloud. You
              will have a choice of file types that you can use, it is a good idea to use the file type that works best
              with the platform it’s using. Some platforms such as YouTube will process almost any file format, while
              Facebook recommends uploading in MP4 format.
            </li>
          </ul>

          <p>
            Before you have completed your project and uploaded it to your platform of choice, it is always a good idea
            to view the completed video. There may be some parts that need to be changed or removed that you might have
            missed in the editing phase.
          </p>

          <p>
            Once you have used your video editing software a few times, the process will become easier. You may then
            find that you need different software that has more features. Remember to check out other people’s videos as
            well to see their video editing techniques.
          </p>
        </article>
      </Layout>
    )
  }
}

export default Page
